import { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import usePrevious from '../global/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from 'assets/images/background.png';
import style from './admin.module.scss';

const AdminStatsDetails = ({IO, IOConnected, onNavigate, options, refresh}) => {
  const [answers, setAnswers] = useState([]);
  const [question, setQuestion] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [info, setInfo] = useState({});
  const [patientCases] = useState([
    {name:'Cas 1', id: '1'},
    {name:'Cas 2', id: '2'},
    {name:'Cas 3', id: '3'}
  ]);

  useEffect(() => {
    if (question === null) {
      setQuestion(options.question);
    }
  }, [question, options?.question]);

  useEffect(() => {
    return () => {
      setAnswers([]);
      setQuestion(null);
      setFetched(false);
    }
  }, []);

  const prevRefresh = usePrevious(refresh);

  useEffect(() => {
    if (IO && IOConnected && question?.id) {
      if(!fetched || refresh !== prevRefresh) {
        IO.emit('question', question.id);
        IO.on('question', (q) => {
            setAnswers(q.answers);
            setInfo(q.info);
            console.log('Question fetched form stat');
        });
        setFetched(true);
      }
    }
  }, [IO, IOConnected, question?.id, fetched,
      refresh, prevRefresh /*important*/]);

  return (
    <>
      <Row className={style.questionDetails}>
        <Col style={{
            backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="stats-top">
            <div><button type="button" onClick={()=>{
              onNavigate('admin-patient-case');
            }}>+</button></div>
            <Row><span>Statistique</span></Row>
            <Row>
              {patientCases &&
                patientCases.map((value, index) => {
                  return (
                    <Col className="nav" key={`stats-details-top-${index}`}>
                      <Button className={
                          `${value.id === options.case ? 'selected' : ''}`
                        } onClick={()=>{
                        onNavigate('admin-stats', {selected: value.id});
                      }}>{value.name}</Button>
                    </Col>
                  )
                })
              }
            </Row>
            {/*<Row style={{
              maxHeight: '10px'
            }}/>*/}
          </Row>
          <Row className="previous">
            <Col>
              <Button onClick={() => {
                onNavigate('admin-stats', {selected: options.case});
              }}>
                <FontAwesomeIcon className="pt-1 mb-1" icon={faArrowLeftLong} />
              </Button>
            </Col>
            <Col>Question {options.questionNumber}</Col>
          </Row>
          <Row className="top-body">
            <Col>
              <div className="board">
                <span>{info.a}</span>
                <span>sur</span>
                <span>{info.b}</span>
              </div>
            </Col>
            <Col className="ques">
              <div>
                <span>{question?.name}</span>
              </div>
            </Col>
          </Row>
          <Row className="body">
            <Col>
              {answers?.length ? answers.map((answer, index) =>
                <Row key={index}>
                  <Col>
                    <div>
                      <span>{answer.nbr}</span>
                    </div>
                  </Col>
                  <Col style={{
                      position: 'relative'
                    }}>
                    <div className="ans">
                      <span>{answer.name}</span>
                    </div>
                    { answer?.points > 0 &&
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: '10px',
                      right: '20px',
                      borderRadius:'5px',
                      width:'10px',
                      height: '10px',
                      color: 'white',
                      border: '1px solid rgba(255, 255, 255, 0.8)',
                      backgroundColor: 'white'
                    }}>
                      <span />
                    </div>
                    }
                    {/*<div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      borderRadius:'50%',
                      width:'40px',
                      height: '40px',
                      // backgroundColor:'red',
                      color: 'white',
                      border: '1px solid white',
                    }}>
                      <span>{answer.points}</span>
                    </div>
                    */}
                  </Col>
                </Row>
              ) : ''}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    );
}

export default AdminStatsDetails;
