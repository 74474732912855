import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import style from './participant.module.scss';
import background from 'assets/images/classico.svg' 
import pistolero from 'assets/images/pistolero.svg' 
import backgroundImage from 'assets/images/background.png'

const ParticipantRules = (props) => {
  const {onNavigate, mode, options} = props;
  const [timer, setTimer] = useState(99999);

  useEffect(() => {
    return () => {
      setTimer(99999);
    };
  }, []);

  useEffect(() => {
    if (timer === 99999) {
      setTimer(options.time);
    }
  }, [options.time, timer]);

  useEffect(() => {
    let timerId;
    if (timer >= 0) {
      timerId = setTimeout(() => {
        const time = timer - 1;
        setTimer(time >= 0 ? time : 0);
        /*if (time <= 0) {
          onNavigate('participant-waiting-for-after-timeout');
        }*/
        clearTimeout(timerId);
      }, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [timer]);

  return (
    <>
      <Row className={`${style.waiting} ${style.rules}`}>
        <Col style={{
            position:'relative',
            backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="title">
            <Row>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col className="panel" style={{
                textAlign:'center',
              }}>
                <span>Explication du mode de jeu</span>
              </Col>
            </Row>
          </Row>
          <Row style={{
            display: 'flex',
            flex:1,
            maxHeight:'100px',
          }}/>
          <Row style={{
            flex: 1,
            display: 'flex',
            maxHeight:'10px',
          }}>
            <Col className="back">
              <Button onClick={() => {
                onNavigate('participant-quizz', {time: timer}/*, {selected: options.questionId}*/);
              }}>
                <FontAwesomeIcon className="pt-1 mb-1" icon={faArrowLeftLong} />
              </Button>
            </Col>
          </Row>{/*
          <div style={{
              flex: 1,
              display: 'flex',
              justifyContent:'center',
              alignItem:'center',
              height:'50%',
              position: 'absolute',
              zIndex:10,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign:'center',
              flexDirection: 'column',
              marginTop:'15%',
              color: 'white',
              textShadow: '1px 1px 2px black',
              fontSize:'1.0em',
              lineHeight: '1.2em',
            }}>
              { mode !== 'pistolero' &&
                <span style={{
                  display: 'inline-block'
                }}>Dans ce mode de jeu seule la rigueur compte. Repondez juste afin d'être recompensé, que vous soyez premier ou dernier.</span>
              }
              { mode === 'pistolero' &&
                <span style={{
                  display: 'inline-block'
                }}>Dans ce mode de jeu c'est la vitesse qui fait la loi. Soyez les premiers à repondre juste pour empocher le maximun de points ! Les suivants n'auront que les miettes du butin.</span>
              }
            </div>*/}
          <Row className="rule-image" style={{
            flex: 1,
            display: 'flex',
          }}>
            <div
              style={{
                // paddingBottom: '110%',
                // marginTop: '18%',
                backgroundImage: `url(${ mode === 'pistolero' ? pistolero : background})`,
              }}
            >
              { mode !== 'pistolero' &&
                <span style={{
                  display: 'inline-block'
                }}>Dans ce mode de jeu seule la rigueur compte. Repondez juste afin d'être recompensé, que vous soyez premier ou dernier.</span>
              }
              { mode === 'pistolero' &&
                <span style={{
                  display: 'inline-block'
                }}>Dans ce mode de jeu c'est la vitesse qui fait la loi. Soyez les premiers à repondre juste pour empocher le maximun de points ! Les suivants n'auront que les miettes du butin.</span>
              }
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ParticipantRules;
