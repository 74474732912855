import { Row, Col } from 'reactstrap';
import style from './admin.module.scss';
import background from 'assets/images/background-logo.png' 
import logoLeft from 'assets/images/logo_jn.png'
import logoRight from 'assets/images/logo_mb.png'
import backgroundImage from 'assets/images/background.png'

const AdminEnding = ({IO, IOConnected, onNavigate, refresh}) => {
  return (
    <Row style={{
        position:'relative',
      }}
      className={style.welcome}>
      <Col className="body" style={{
        position:'relative',
        backgroundImage:`url(${backgroundImage})`,
        textAlign:'center',
      }}>
        <span style={{
          padding: '20px',
          top: '10%',
          left: 0,
          position: 'absolute',
          color: 'white',
          display: 'inline-block',
          fontSize:'1.2em',
        }}>
          Nous vous remercions d'avoir participé à
          la 1ère édition de notre émission
        </span>
        <div
          className="image"
          style={{
            backgroundImage: `url(${background})`
          }}
        >
        </div>
        <div className="bottom">
          {/*<Row>
            <Button onClick={start}>Commencer</Button>
          </Row>
          */}
          <Row />
          {/*
          <Row>
            <Col className="col-8">
              <div style={{backgroundImage:`url(${logoRight})`}} className="logo" />
            </Col>
            <Col className="col-4">
              <div style={{backgroundImage:`url(${logoLeft})`}} className="logo" />
            </Col>
          </Row>
        */}
        </div>
      </Col>
    </Row>
  );
}

export default AdminEnding;
