/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { getBadges } from '../global/Util';
import backgroundImage from 'assets/images/background.png';
import style from './admin.module.scss' ;

// todo review
const cities =
['',
'Albi',
'Bayonne',
'Rennes',
'Pornichet',
'Paris',
'Amiens',
'Grenoble',
'Marseille'];

const AdminRanking = (props) => {
  const { IO, IOConnected, refresh, onNavigate, options } = props;
  const [ participants, setParticipants ] = useState(null);

  useEffect(()=>{
    return ()=>{
      setParticipants(null);
    };
  }, []);

  useEffect(() => {
    if (IO && IOConnected) {
      IO.emit('ranking');
      IO.on('ranking', (result) => {
        setParticipants(result);
      });
    }
  }, [IO, IOConnected, refresh /*important*/]);

  const orderByTotal = ( a, b ) => {
    if ( a.total > b.total ) {
      return -1;
    } else if ( a.total < b.total ) {
      return 1;
    }
    return 0;
  }

  return (
  <>
    {
      <Row className={style.ranking}>
        <Col
          style={{
            backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="ranking-top">
            <h1>Classement</h1>
            <div><button type="button" onClick={()=>{
              const opts = {...options};
              delete opts.origin;
              onNavigate(options.origin, opts);
            }}>+</button></div>
          </Row>
          <Row className="ranking-row">
            <Col><span>Position</span></Col>
            <Col><span>Ville</span></Col>
            <Col>
              <Row>
                <Col>
                  <Row>
                    <Col><span>Cas 1</span></Col>
                    <Col><span>Cas 2</span></Col>
                    <Col><span>Cas 3</span></Col>
                  </Row>
                </Col>
                <Col className="total">Total</Col>
              </Row>
            </Col>
          </Row>
          <Row className="container">
            {
              participants &&
              participants.sort(orderByTotal).map((participant, index) => {
                const badge = getBadges(participant.city_id);
                return <Row className="participant ranking-row" key={index}>
                  <Col className="position"><div><i>{index+1}</i></div></Col>
                  <Col>
                    <a
                      data-content={cities[participant.city_id]}
                      href="#" className="badge" style={{
                        backgroundImage: badge ? `url(${badge.data})` : 'unset',
                      }}
                    />
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Row>
                          { // todo review
                            Array(3).fill(0).map((_, i) => {
                              const f = participant.data.find((e)=> e.id === i+1);
                              return <Col key={`cp-${i}-${index}`}>
                                {f &&
                                  <i>{f.points}</i>
                                }
                                {!f &&
                                  <i>0</i>
                                }
                              </Col>
                            })
                          }
                        </Row>
                      </Col>
                      <Col className="total">{participant.total}</Col>
                    </Row>
                  </Col>
                </Row>
              })
            }
          </Row>
        </Col>
      </Row>
    }
  </>
  );
}

export default AdminRanking;
