import { useEffect, useState, useRef, forwardRef } from 'react';
import io from 'socket.io-client';
import { Button } from 'reactstrap';
import ParticipantView from './participant/ParticipantView';
import AdminView from './admin/AdminView';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import Stream from './global/Stream';
import style from './app.module.scss';

const ROLES = {
  animator: 'admin',
  participant: 'participant',
  observer: 'spectator'
};

const Unmuter = forwardRef((props, player) => {
  const { muted } = props;
  const [ muteVisibility, setMuteVisibility ] = useState(true);

  useEffect(()=>{
    return () => {
      setMuteVisibility(true);
    };
  }, []);

  return <> 
    <Button
      style={{
        position: 'absolute',
        borderRadius:'50%',
        bottom: '25px',
        right: '25px',
        zIndex: 999,
        fontSize: '20px',
        width: '25px',
        height: '25px',
        display: `${(muteVisibility  && muted) ? 'flex' : 'none'}`,
        alignItems:'center',
        justifyContent:'center',
      }}
      onClick={()=>{
        player.current.muted = false;
        setMuteVisibility(false);
      }}
    >
      <FontAwesomeIcon className="pt-1 mb-1" icon={faVolumeXmark}/>
    </Button>
  </>          
});

const App = (props) => {
  // const { location } = props;
  const [IO, setIO] = useState(null);
  const [IOConnected, setIOConnected] = useState(false);
  const [viewMode, setViewMode] = useState('participant');
  const [user, setUser] = useState(null);
  const [muted, setMuted] = useState(false);
  
  const player = useRef(null);  
  
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role');
  const username = searchParams.get('username');
  const email = searchParams.get('email');
  const extra = searchParams.get('extra');
  const room = 1; // searchParams.get('room');

  useEffect(() => {
    // animator, participant ou observer
    const ROLE = ROLES[role];
    const opt = { query: `role=${ROLE}&username=${username}&email=${email}&room=${room}` };
    console.log(opt);
    if (!user) {
      setUser({
        role:ROLE, username, email, room,
      });
    }
    setIO(io(`${process.env.REACT_APP_SERVER_URL}`, opt));
  }, [email, role, room, user, username]);

  useEffect(() => {
    if (IO) {
      if (!IOConnected) {
        IO.connect(/*{rejectUnauthorized: false}*/);
      }
      IO.on('connect', () => {
        setIOConnected(true);
        console.log('on connect');
      });
      IO.on('disconnect', () => {
        setIOConnected(false);
        console.log('on disconnect');
      });
    }
  }, [IO, IOConnected]);

  useEffect(()=>{
    console.log(searchParams);
  }, [searchParams]);

  useEffect(() => {
    const ROLE = ROLES[role];
    if (ROLE) {
      if (ROLE === 'admin') { //
        setViewMode('admin');
      } else if(ROLE === 'spectator') { // 
        setViewMode('spectator');
      }
    }
  }, [role]);

  useEffect(()=>{
    return ()=>{
      setIO(null);
      setIOConnected(false);
      setViewMode('participant');
      setUser(null);
      setMuted(false);
    }
  }, []);
  
  return (
    <div className={style.app} style={{
      display: 'flex',
      position: 'relative',
      maxHeight: '100vh',
      flex:1,
      flexDirection: 'row',
      padding: 0,
      margin:0,
      justifyContent:'center',
      alignItems:'center',
      overflow:'hidden',
    }}>
      <div style={{
        display: 'flex',
        height: '100%',
        flexBasis: '100%',
        justifyContent:'center',
        alignItems:'center',
        flex:1,
      }}>
        <div style={{
          width:'100%',
          display: 'flex',
          justifyContent: 'center'
        }}>
          {/*<Unmuter muted={muted} ref={player} />*/}
          <Stream
            IO={IO}
            IOConnected={IOConnected}
            role={user?.role}
            ref={player}
            onMute={()=>{
              setMuted(true);
            }}
          />
        </div>
      </div>
      { viewMode !== 'spectator' &&
        <div style={{
          display: 'flex',
          height: '100%',
          minWidth:'260px',
          width: '26%',
        }}>
          { viewMode === 'participant' &&
            <ParticipantView IO={IO} IOConnected={IOConnected} user={user} />
          }
          {/* viewMode === 'spectator' &&
            <SpectatorView IO={IO} IOConnected={IOConnected} user={user} />
          */}
          { viewMode === 'admin' &&
            <AdminView IO={IO} IOConnected={IOConnected} user={user} extra={extra} />
          }
        </div>
      }
    </div>
  );
}

export default App;
