import { useEffect, useRef } from 'react';
// Could be moved to data base
import albi from '../assets/images/badges/badge_ville_albi_128.png';
import amiens from '../assets/images/badges/badge_ville_amiens_128.png';
import bayonne from '../assets/images/badges/badge_ville_bayonne_128.png';
import grenoble from '../assets/images/badges/badge_ville_grenoble_128.png';
import marseille from '../assets/images/badges/badge_ville_marseille_128.png';
import paris from '../assets/images/badges/badge_ville_paris_128.png';
import pornichet from '../assets/images/badges/badge_ville_pornichet_128.png';
import rennes from '../assets/images/badges/badge_ville_rennes_128.png';
import albi256 from '../assets/images/badges/badge_ville_albi_256.png';
import amiens256 from '../assets/images/badges/badge_ville_amiens_256.png';
import bayonne256 from '../assets/images/badges/badge_ville_bayonne_256.png';
import grenoble256 from '../assets/images/badges/badge_ville_grenoble_256.png';
import marseille256 from '../assets/images/badges/badge_ville_marseille_256.png';
import paris256 from '../assets/images/badges/badge_ville_paris_256.png';
import pornichet256 from '../assets/images/badges/badge_ville_pornichet_256.png';
import rennes256 from '../assets/images/badges/badge_ville_rennes_256.png';

const BADGES = [
  { 
    id: 1,
    data: albi,
    data256: albi256
  },
  {
    id: 2,
    data: bayonne,
    data256: bayonne256
  },
  {
    id: 3,
    data: rennes,
    data256: rennes256
  },
  {
    id: 4,
    data: pornichet,
    data256: pornichet256
  },
  {
    id: 5,
    data: paris,
    data256: paris256
  },
  {
    id: 6,
    data: amiens,
    data256: amiens256
  },
  {
    id: 7,
    data: grenoble,
    data256: grenoble256
  },
  {
    id: 8,
    data: marseille,
    data256: marseille256
  },
];

export const getBadges = (id) => {
  return BADGES.find((element) => {
    return id === element.id;
  });
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
