import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../global/Modal';
import usePrevious from '../global/Util';
import backgroundImage from 'assets/images/background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import starImage from 'assets/images/star.png';
import style from './admin.module.scss';

const QuestionsDetails = ({IO, IOConnected, onNavigate,
  options, refresh, currentQuestions}) => {
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalReturn, setShowModalReturn] = useState(false);
  const [question, setQuestion] = useState(null);
  const [nextIndex, setNextIndex] = useState(0);
  const [fetched, setFetched] = useState(false);
  const [innerRefresh, setInnerRefresh] = useState(0);
  const [info, setInfo] = useState({});
  const [showResetModal, setShowResetModal] = useState(false);

  const validateReset = useCallback(() => {
    if (IO && IOConnected) {
      IO.emit('reset-session');
      // onNavigate('admin-welcome');
    }
  }, [IO, IOConnected]);

  const hasNextQuestion = useCallback(() => {
    const index = currentQuestions.findIndex(
      (q) => question.id === q.id) + 1;
    return index;
  }, [currentQuestions, question?.id]);

  const validateReturn = useCallback(() => {
    if (IO && IOConnected) {
        IO.emit('navigate-to', 'participant-waiting-for-next-question');
        onNavigate('admin-patient-case');
        setShowModalReturn(false);
      }
      
  }, [IO, IOConnected, onNavigate]);

  const validate = useCallback(() => {
    if (IO && IOConnected) {
      const index = hasNextQuestion();
      if (index < currentQuestions.length) {
        setNextIndex(index + 1);
        IO.emit('navigate-with-params-to', {
          navigation: 'participant-quizz',
          params: {id: currentQuestions[index].id}
        });
        setQuestion({...currentQuestions[index]});
        setFetched(false);
      }
      setShowModal(false);
    }
  }, [IO, IOConnected, currentQuestions, hasNextQuestion]);

  useEffect(() => {
    if (question === null) {
      setQuestion(options.question);
    }
  }, [question, options?.question]);

  useEffect(() => {
    return () => {
      setAnswers([]);
      setShowModal(false);
      setShowModalReturn(false);
      setQuestion(null);
      setNextIndex(0);
      setFetched(false);
      setInnerRefresh(0);
      setInfo({});
      setShowResetModal(false);
    }
  }, []);

  const previous = usePrevious({refresh, innerRefresh});

  useEffect(()=>{
    let id;
    if (IO && IOConnected) {
      IO.on('new-answer', () => {
        console.log('new-answer');
        clearTimeout(id);
        id = setTimeout(()=>{
          setInnerRefresh(innerRefresh+1);
        }, 500);
      });
    }
    return ()=>{
      clearTimeout(id);
    }
  }, [IO, IOConnected, innerRefresh]);

  useEffect(() => {
    if (IO && IOConnected && question?.id) {
      if(!fetched ||
          +refresh !== +previous.refresh ||
          +innerRefresh !== +previous.innerRefresh
        ) {        
        IO.emit('question', question.id);
        IO.on('question', (q) => {
            setInfo(q.info);
            setAnswers(q.answers);
            setNextIndex(hasNextQuestion());
            console.log('Question fetched');
        });
        setFetched(true);
      }
    }
  }, [IO, IOConnected, previous, question?.id, hasNextQuestion,
      fetched, refresh, innerRefresh /*important*/]);

  return (
    <>
      {
        showResetModal && <>
          <Modal
            title="test"
            onValidate={validateReset}
            onCancel={()=>{
              setShowResetModal(false);
            }}
          />
        </>
      }
      {
        showModal && <>
          <Modal
            title="..."
            titleDetail="..."
            onValidate={validate}
            onCancel={()=>{
              setShowModal(false);
            }}
          />
        </>
      }
      {
        showModalReturn && <>
          <Modal
            title="..."
            titleDetail="..."
            onValidate={validateReturn}
            onCancel={()=>{
              setShowModalReturn(false);
            }}
          />
        </>
      }
      <Row className={style.questionDetails}>
        <Col style={{
              backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="header">
            <Row>
              <Col>
                <Button disabled={false} onClick={()=>{
                  onNavigate('admin-stats');
                }}><span>Statistiques</span></Button>
              </Col>
              <Col>
                <Button disabled={false} onClick={()=>{
                  const opts = {...options};
                  opts.question = question;
                  onNavigate('admin-ranking', {
                    origin: 'admin-question-details',
                    ...opts,
                  });
                }}><span>Classement</span></Button>
              </Col>
              <Col>
                <Button onClick={() => {
                    setShowResetModal(true);
                  }}><span>Reset</span></Button>
              </Col>
            </Row>
            {/*<Row />*/}
          </Row>
          <Row className="previous">
            <Col>
              <Button onClick={() => {
                onNavigate('admin-questions', {selected: options.case,
                  patientCaseNumber:options.patientCaseNumber});
              }}>
                <FontAwesomeIcon className="pt-1 mb-1" icon={faArrowLeftLong} />
              </Button>
            </Col>
            <Col>Question {nextIndex/*options.questionNumber*/}</Col>
          </Row>
          <Row className="top-body">
            <Col>
              <div>
                <span>{info.a}</span>
                <span>sur</span>
                <span>{info.b}</span>
              </div>
            </Col>
            <Col className="ques">
              <div>
                <span>{question?.name}</span>
              </div>
            </Col>
          </Row>
          <Row className="body">
            <Col>
              {answers?.length ? answers.map((answer, index) =>
                <Row key={index}>
                  <Col>
                    <div>
                      <span>{answer.nbr}</span>
                    </div>
                  </Col>
                  <Col style={{
                      position: 'relative'
                    }}>
                    <div className="ans">
                      <span>{answer.name}</span>
                    </div>
                    { answer?.points > 0 && question?.gameMode === 'pistolero' &&
                      <div className="pistolero-badge" style={{
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        right: '20px',
                        top: '10px',
                        backgroundImage: `url(${starImage})`
                      }} />
                    }
                    { answer?.points > 0 && question.gameMode !== 'pistolero' &&
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: '10px',
                      right: '20px',
                      borderRadius:'50%',
                      width:'10px',
                      height: '10px',
                      color: '#4B80AC',
                      border: '1px solid rgba(255, 255, 255, 0.8)',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      fontSize:'12px',
                    }}>
                      <span />
                    </div>
                    }
                  </Col>
                </Row>
              ) : ''}
            </Col>
          </Row>
          <Row className="footer">
            <Col>
              {
                nextIndex >= currentQuestions.length ?
                <Button 
                  onClick={() => {
                    setShowModalReturn(true);
                  }}>Retourner aux cas patients</Button> :
                <Button 
                  disabled={nextIndex >= currentQuestions.length}
                  onClick={() => {
                  setShowModal(true);
                }}>Question suivante</Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    );
}

export default QuestionsDetails;
