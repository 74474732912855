import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../global/Modal';
import usePrevious from '../global/Util';
import backgroundImage from 'assets/images/background.png';
import style from './admin.module.scss';

const AdminPatientCases = ({IO, IOConnected, onNavigate, refresh}) => {
  const [patientCases, setPatientCases] = useState([]);
  const [selected, setSelected] = useState(null);
  const [patientCaseNumber, setPatientCaseNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [fetched, setFetched] = useState(false);

  const validateReset = useCallback(() => {
    if (IO && IOConnected) {
      IO.emit('reset-session');
      // onNavigate('admin-welcome');
    }
  }, [IO, IOConnected]);

  const validate = useCallback(() => {
    if (IO && IOConnected) {
      if (selected !== -1) {
        // IO.emit('navigate-to', 'participant-waiting-for-question');
        IO.emit('navigate-with-params-to', {
          navigation: 'participant-waiting-for-question',
          params: {id: selected, patientCaseNumber}
        });
        onNavigate('admin-questions', {selected, patientCaseNumber});
      } else {
        setShowModal(false);
        IO.emit('navigate-to', 'participant-ending');
        // onNavigate('admin-ending');
      }
    }
  }, [IO, IOConnected, onNavigate, selected, patientCaseNumber]);
  
  useEffect(() => {
    return () => {
      setPatientCases([]);
      setSelected(null);
      setShowModal(false);
      setShowResetModal(false);
      setFetched(false);
    }
  }, []);

  const prevRefresh = usePrevious(refresh);

  useEffect(() => {
    if (IO && IOConnected) {
      if(!fetched || +refresh !== +prevRefresh) {
        IO.emit('patient-cases');
        IO.on('patient-case', (result) => {
          setPatientCases(result);
          console.log('Patient Cases fetched');
        });
        setFetched(true);
      }
    }
  }, [IO, IOConnected, fetched, refresh, prevRefresh /*important*/]);

  return (
    <>
      {
        showResetModal && <>
          <Modal
            title="test"
            onValidate={validateReset}
            onCancel={()=>{
              setShowResetModal(false);
            }}
          />
        </>
      }
      {
        showModal && <>
          <Modal
            title="test"
            onValidate={validate}
            onCancel={()=>{
              setShowModal(false);
            }}
          />
        </>
      }
      <Row className={style.patientCase}>
        <Col style={{
            backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="header">
            <Row>
              <Col>
                <Button disabled={false} onClick={()=>{
                  onNavigate('admin-stats');
                }}><span>Statistiques</span></Button>
              </Col>
              <Col>
                <Button disabled={false} onClick={()=>{
                  onNavigate('admin-ranking', {origin: 'admin-patient-case'});
                }}><span>Classement</span></Button>
              </Col>
              <Col>
                <Button onClick={() => {
                    setShowResetModal(true);
                  }}><span>Reset</span></Button>
              </Col>
            </Row>
            {/*<Row />*/}
          </Row>
          <Row className="body">
            <Col>
              {patientCases?.length ? patientCases.map((patientCase, index) =>
                <Row className="selectable" key={index}>
                  <Col>
                    <Button
                      className={`${patientCase.id === selected ? 'selected' : ''}`}
                      // outline={!patientCase.done}
                      style={{
                        opacity: `${patientCase.done ? 0.5 : 1}`,
                      }}
                      disabled={patientCase.done}
                      color="secondary"
                      onClick={()=>{
                        setSelected(patientCase.id);
                        setPatientCaseNumber(index + 1);
                      }}
                    >{patientCase.name}</Button>
                  </Col>
                </Row>
              ) : ''}
                <Row className="selectable">
                  <Col>
                    <Button
                      className={`${selected === -1 ? 'selected' : ''}`}
                      outline
                      color="secondary"
                      onClick={()=>{
                        setSelected(-1);
                      }}
                    >Fin d'émission</Button>
                  </Col>
                </Row>
            </Col>
          </Row>
          <Row className="footer">
            <Col>
              <Button
                disabled={selected === null}
                onClick={()=>{
                setShowModal(true);
              }}>Valider</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    );
}

export default AdminPatientCases;
