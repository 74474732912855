import { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { getBadges } from '../global/Util';
import style from './participant.module.scss' ;
import backgroundImage from 'assets/images/background.png';

const ParticipantCities = (props) => {
  const { IO, IOConnected, onNavigate, refresh } = props;
  const [ cities, setCities ] = useState(null);

  useEffect(()=>{
    return ()=>{
      setCities(null);
    }
  }, []);

  const renderCols = useCallback((index) => {
    const citiesList = [];
    Array(3).fill(0).forEach((_, cell) => {
      const cityIndex = (index * 3) + cell;
      const city = cities[cityIndex];
      if (city) {
        const badge = getBadges(city.id);
        citiesList.push(
          <Col
            className={`city ${city.registered ? 'disabled' : ''}`}
            key={`${index} ${cell}`}
            onClick={()=>{
              if (!city.registered) {
                onNavigate('participant-city', {city});
              }
            }}>
            {/*<span
              style={{
                color: city.registered ? 'rgba(255, 255, 255, 0.5)' : 'white'
              }}
            >{`${city.name}`}</span>
            */}
            <i className="badge" style={{
              opacity: city.registered ? 0.5 : 1,
              backgroundImage: badge ? `url(${badge.data})` : 'unset',
            }}/>
          </Col>
        );
      } else {
        citiesList.push(<Col key={`empty-${cityIndex}`}/>);
      }
    });
    return (
      <>
        {citiesList}
      </>
    );
  }, [cities, onNavigate]);

  const renderRows = useCallback(() => {
    const rows = [];
    Array(Math.ceil(cities.length / 3)).fill(0).forEach((_, index) =>
      rows.push(
        <Row key={index}>
          {
            renderCols(index)
          }
        </Row>
      )
    )
    return rows;
  }, [cities?.length, renderCols]);

  useEffect(() => {
    if (IO && IOConnected) {
      IO.emit('cities');
      IO.on('cities', (result) => {
        setCities(result);
      });
    }
  }, [IO, IOConnected, refresh /*important*/]);

  return (
  <>
    <Row className={style.cities}>
      <Col style={{
          backgroundImage:`url(${backgroundImage})`,
        }}>
        <Row className="header">
          <h1>Sélectionnez votre ville</h1>
        </Row>
        <Row className="body">
          <Col>
            { cities ?
              renderRows()
              : ''
            }
          </Col>
        </Row>
        {/*<Row className="footer">
          <Col>footer</Col>
        </Row>
        */}
      </Col>
    </Row>
  </>
  );
}

export default ParticipantCities;
