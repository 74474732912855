import { Row, Col } from 'reactstrap';
import style from '../admin/admin.module.scss';
import background from 'assets/images/background-logo.png' 
import logoLeft from 'assets/images/logo_jn.png'
import logoRight from 'assets/images/logo_mb.png'
import backgroundImage from 'assets/images/background.png'

const ParticipantEnding = ({IO, IOConnected, onNavigate, refresh}) => {
  return (
    <Row style={{
        position:'relative',
      }}
      className={style.welcome}>
      <Col className="body" style={{
        position:'relative',
        backgroundImage:`url(${backgroundImage})`,
        textAlign:'center',
      }}>
        <span style={{
          padding: '5px',
          left: '0px',
          top: '0px',
          width: '100%',
          height: '100px',
          display: 'flex',
          // place-content: 'center;
          position: 'absolute',
          color: 'white',
          fontFamily: 'Roboto-Bold',
          fontSize: '22px',
          lineHeight: '22px',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <span style={{
            display: 'inline-block',
          }}>Nous vous remercions d'avoir participé à
            la 1ère édition de notre émission</span>
        </span>
        <Row className="image">
          <div
            style={{
              backgroundImage: `url(${background})`
            }}
          >
          </div>
        </Row>
        <div className="bottom">
          <Row />
          {/*
          <Row>
            <Col className="col-8">
              <div style={{backgroundImage:`url(${logoRight})`}} className="logo" />
            </Col>
            <Col className="col-4">
              <div style={{backgroundImage:`url(${logoLeft})`}} className="logo" />
            </Col>
          </Row>
          */}
        </div>
      </Col>
    </Row>
  );
}

export default ParticipantEnding;
