import { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import ParticipantWelcome from './ParticipantWelcome';
import ParticipantCities from './ParticipantCities';
import ParticipantCity from './ParticipantCity';
import ParticipantWaiting from './ParticipantWaiting';
import ParticipantPatientCase from './ParticipantPatientCase';
import ParticipantRules from './ParticipantRules';
import ParticipantQuizz from './ParticipantQuizz';
import ParticipantEnding from './ParticipantEnding';
import style from './participant.module.scss';

const AVAILABLE_VIEWS  = [
  'participant-welcome',
  'participant-cities',
  'participant-city',
  'participant-waiting-for-participants',
  'participant-patient-case',
  'participant-rules',
  'participant-waiting-for-patient-case',
  'participant-quizz',
  'participant-waiting-for-question',
  'participant-waiting-for-next-question',
  'participant-waiting-for-after-timeout',
  'participant-ending',
  'participant-disconnected',
  'participant-rules-pistolero',
];

const ViewParticipant = (props) => {
  const { IO, IOConnected } = props;
  const [currentView, setCurrentView] = useState('participant-welcome');
  const [currentViewOptions, setCurrentViewOptions] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [isRegistered, setIsRegistered] = useState(false);

  const onRegister = useCallback(() => {
    setIsRegistered(true);
  }, []);

  useEffect(() => {
    return ()=>{
      setCurrentView('participant-welcome');
      setCurrentViewOptions({});
      setRefresh(0);
      setIsRegistered(false);
    }
  }, []);

  useEffect(() => {
    if (IO && IOConnected) {
      IO.on('get-back-to', (data)=>{
        // setCurrentViewOptions(data.options);
        setCurrentView(data.view);
        if (data.view !== 'participant-welcome') {
          setIsRegistered(true);
        }
        console.log('get-back-to', data);
      });
      IO.on('navigate-to', (view) => {
        if(view === 'participant-welcome') {
          setIsRegistered(false);
          setCurrentView('participant-welcome');
        } else if (isRegistered) {
          setCurrentView(view);
        } else {
          setCurrentView('participant-disconnected');
        }
      });
      IO.on('refresh', () => {
        setRefresh(refresh+1);
      });
    }
  }, [IO, IOConnected, isRegistered, refresh]);

  const onNavigate = useCallback((view, options) => {
    if (options) {
      setCurrentViewOptions(options);
    } else {
      setCurrentViewOptions({});
    }
    setCurrentView(view);
    IO.emit('save-current-view', {view, options});
    console.log('save-current-view', view);
  }, [IO]);

  /*
  const renderStream = useCallback(() => {
    return <Stream
        IO={IO}
        IOConnected={IOConnected}
        role={user?.role}
      />
  }, [IO, IOConnected, user?.role]);
  */

  return (
    <Row className={style.view}>
      {/*<Col>
        renderStream()
      </Col>*/}
      <Col style={{
          height: '100vh',
          position: 'relative',
          padding:'10px',
          width: '100%',
        }}>
        {
          AVAILABLE_VIEWS.indexOf(currentView) === -1 ? <>
            <div>unvailable view</div>
          </> :
          <>
            {currentView === 'participant-welcome' &&
              <ParticipantWelcome
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-cities' &&
              <ParticipantCities
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-city' &&
              <ParticipantCity
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                city={{
                  name:'Nom de la Ville',
                }}
                options={currentViewOptions}
                refresh={refresh}
                onRegister={onRegister}
              />
            }
            {currentView === 'participant-waiting-for-participants' &&
              <ParticipantWaiting
                mode="for-participants"
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-patient-case' &&
              <ParticipantPatientCase
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-rules' &&
              <ParticipantRules
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-rules-pistolero' &&
              <ParticipantRules
                IO={IO}
                mode="pistolero"
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-waiting-for-patient-case' &&
              <ParticipantWaiting
                mode="for-patient-case"
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-quizz' &&
              <ParticipantQuizz
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-waiting-for-question' &&
              <ParticipantWaiting
                mode="for-question"
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
                title="Préparez vous !"
              />
            }
            {currentView === 'participant-waiting-for-after-timeout' &&
              <ParticipantWaiting
                mode="for-after-timeout"
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
                title="Temps écoulé"
              />
            }
            {currentView === 'participant-waiting-for-next-question' &&
              <ParticipantWaiting
                mode="for-next-question"
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-ending' &&
              <ParticipantEnding
                IO={IO}
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
            {currentView === 'participant-disconnected' &&
              <ParticipantEnding
                IO={IO}
                mode='disconnected'
                IOConnected={IOConnected}
                onNavigate={onNavigate}
                options={currentViewOptions}
                refresh={refresh}
              />
            }
          </>
        }
      </Col>
    </Row>
  );
}

export default ViewParticipant;
