import { Row, Col } from 'reactstrap';
import style from './participant.module.scss';

const ParticipantPatientCase = ({refresh}) => {
  return (
    <Row className={style.patientCase}>
      <Col>
       <span>...</span>
      </Col>
    </Row>
  );
}

export default ParticipantPatientCase;
