import { useEffect, useState, useRef, forwardRef } from 'react';
import { Row, Col } from 'reactstrap';
// import Plyr from 'plyr-react'
// import 'plyr-react/dist/plyr.css'
import style from './stream.module.scss' ;
import VideoJS from './player';

const Stream = forwardRef((props, player) => {
  const {IO, IOConnected, role, onMute} = props;
  const [hls, setHls] = useState('');
  const [muted, setMuted] = useState(false);

  const hlsRef = useRef();

  useEffect(() => {
    let mounted = true;
    if (IO && IOConnected) {
      if (role !== 'admin') {
        IO.on('set-hls-url', (url) => {
          console.log('set-hls-url');
          if (mounted) {
            // setMuted(true);
            setHls(url);
          }
        });
      }
      IO.on('set-hls-url-on-connection', (url) => {
        console.log('set-hls-url-on-connection');
        if (mounted) {
          setMuted(true);
          setHls(url);
        }
      });
    }
    return () => mounted = false;
  }, [IO, IOConnected, role]);

  useEffect(()=>{
    if (player?.current && hls !== '') {
      player.current.addEventListener('loadstart', ()=>{
        if (muted) {
          player.current.muted = true;
          onMute();
        }
        player.current.play().catch(()=>{
          player.current.muted = true;
          onMute();
          player.current.play();
        });
      });
      player.current.load();
    }
  }, [hls, muted, onMute, player]);

  useEffect(()=>{
    return () => {
      setHls('');
      setMuted(false);
    }
  }, []);


  const handlePlayerReady = ((videojs) => {
    videojs.on('waiting', () => {
      videojs.log('player is waiting');
    });
    videojs.on('dispose', () => {
      videojs.log('player will dispose');
    });
  })

  return (
  <>
    <Row className={style.stream} style={{
      position: 'relative'
    }}>
      {role === 'admin' &&
        <Col style={{
          position: 'absolute',
          zIndex: 1000,
          left: 0,
          top: '-35px',
        }}>
          <label htmlFor="hls">HLS</label>
          <input type="text" name="hls" ref={hlsRef}/>
          <button type="button" onClick={(e)=>{
            if(IO && IOConnected && hlsRef?.current) {
              const url = hlsRef.current.value;
              setHls(url);
              IO.emit('set-hls-url', url);
            }
          }}>valider</button>
        </Col>
      }
      <Col>
        <div
          style={{
            position: 'relative',
            paddingTop: '56.25%',
          }}
        >
          <VideoJS
            style={{
              width:'100%',
              height: '100%',
            }}
            ref={player}
            options={{
              children: {
                controlBar: {
                  children: {
                    muteToggle: true,
                    volumeControl: true,
                  }
                }
              },
              autoplay: false,
              controls: true,
              responsive: true,
              fluid: true,
              sources: [{
                src: hls, //'https://d7inta1ixqks4.cloudfront.net/out/v1/131e0bdf1cfb4d45a55c5e6ae56c4160/index.m3u8',
                type: 'application/x-mpegURL'
              }]
            }}
            onReady={handlePlayerReady}
          />
        </div>
      </Col>
    </Row>
  </>
  );
});

export default Stream;
